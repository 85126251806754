import React from 'react';
import { Provider } from 'react-redux';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';

import store from './store';
import BabyApp from './BabyApp';

loadableReady(() => {
  const root = document.getElementById('root-nav');
  hydrate(
    <Provider store={store}>
      <BabyApp />
    </Provider>,
    root
  );
}).catch((err) => {
  // eslint-disable-next-line no-console
  console.log(err);
});
