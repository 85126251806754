import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import BabyNavContainer from 'components/BabyNav/BabyNavContainer';

import featureFlags from './util/featureFlags';

import './stylesheets/core.less';

const ModalRoot = loadable(() => import('./components/common/ModalRoot'), { ssr: false });
const ToastsContainer = loadable(() => import('./components/toastsContainer'), { ssr: false });
const FeatureToggle = loadable(() => import('./components/FeatureToggle'), { ssr: false });

function BabyApp() {
  const [showFeatureToggle, setShowFeatureToggle] = useState(false);

  useEffect(() => {
    setShowFeatureToggle(featureFlags.get('featureToggle'));
  }, []);

  return (
    <React.Fragment>
      <a href="/webaccessibility" tabIndex={0} className="accessibility-link screen-only">
        Web Accessibility
      </a>
      <BabyNavContainer />
      <ModalRoot />
      <ToastsContainer />
      {showFeatureToggle && <FeatureToggle />}
    </React.Fragment>
  );
}

export default BabyApp;
